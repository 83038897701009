import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BlockPicture from "../structure/blockPicture.component"
import EscdaLazy from "./escda/escdaLazy.component"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Trans, useTranslation } from "../translation/translate.component"

const EscdaSentence = styled.div`
  color: ${props => props.theme.colors.primary};
  font-weight: 900;
  margin-left: 25px;
`

export default function ThreeBlockPicture ({ withEscdaBlock = true }) {
  const { t } = useTranslation()
  const { block1, block2, block3 } = useStaticQuery(query)

  return (
    <>
      <BlockPicture title={t(`common:landing.section_picture_solutions_title`)} img={block1} pictureSide="right">
        {t(`common:landing.section_picture_solutions_body`)}
      </BlockPicture>
      <BlockPicture title={t(`common:landing.section_picture_serenity_title`)} img={block2}>
        {t(`common:landing.section_picture_serenity_body`)}
      </BlockPicture>
      {withEscdaBlock && (
        <BlockPicture title={t(`common:landing.section_picture_escda_title`)} img={block3} pictureSide="right">
          <Trans t={t} i18nKey="common:landing.section_picture_escda_body_1" />
          <EscdaLazy>
            <EscdaSentence>{t(`common:landing.section_picture_escda_body_2`)}</EscdaSentence>
          </EscdaLazy>
        </BlockPicture>
      )}
    </>
  )
}

ThreeBlockPicture.propTypes = {
  withEscdaBlock: PropTypes.bool,
}

const query = graphql`
  query {
    block1: file(name: { eq: "home_plombier" }) {
      ...responsiveFileImage600
    }
    block2: file(name: { eq: "home_famille" }) {
      ...responsiveFileImage600
    }
    block3: file(name: { eq: "home_electricien" }) {
      ...responsiveFileImage600
    }
  }
`
