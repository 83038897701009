import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Image } from "../../components/images.component"
import breakpoint from "styled-components-breakpoint"
import { condition, fontSizes } from "./theme"
import { flexCenter, fullWidthMobile } from "./theme/styles.component"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const BlockTitle = styled.h3`
  color: ${props => props.theme.colors.grey.g800};
  text-align: left;
  font-size: ${fontSizes(`large`)};
  font-weight: 900;
  width: 100%;
  line-height: 26px;
  margin-bottom: 5px;

  ${breakpoint(`medium`)`
    line-height: 28px;
  `}
`

const Content = styled.div`
  max-width: 100%;
  line-height: 26px;

  ${breakpoint(`medium`)`
    line-height: 28px;
  `}
`

const Picture = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${breakpoint(`medium`)`
    width: 50%;
    margin: 0;
  `}
`

const ImageWrapper = styled.div`
  display: flex;
  ${fullWidthMobile};

  ${breakpoint(`small`)`
    margin: auto;
    width: 60%;
  `}

  ${breakpoint(`medium`)`
    width: 95%;
  `}
`

const Text = styled.div`
  ${flexCenter};
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  order: 1;

  ${breakpoint(`medium`)`
    width: 50%;
    padding: 0 60px;
    margin: 0;
    order: ${condition(`left`, 0, 2)};
  `}
`

export default function BlockPicture ({ pictureSide = `left`, img, title, children }) {
  return (
    <Wrapper data-testid="block_picture">
      <Text left={pictureSide !== `left`}>
        <BlockTitle>{title}</BlockTitle>
        <Content>{children}</Content>
      </Text>
      <Picture>
        <ImageWrapper>
          <Image file={img} alt={title} />
        </ImageWrapper>
      </Picture>
    </Wrapper>
  )
}

BlockPicture.propTypes = {
  title: PropTypes.string.isRequired,
  pictureSide: PropTypes.oneOf([`left`, `right`]),
  img: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}
